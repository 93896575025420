/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { useForm, Controller } from "react-hook-form";

import { AuthContext } from "../contexts/Auth.Context";
import { ApisContext } from "../contexts/ApiConterxt";

import axios from "axios";
import { toast } from "react-toastify";
import { TiDeleteOutline } from "react-icons/ti";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Select from "react-select";
import ImageResizer from "../components/dashboard/ImageResizerComponent";

const EditProduct = () => {
  let { productId } = useParams();
  productId = parseInt(productId);

  const [product, setProduct] = useState();

  const [publishedYear, setPublishedYear] = useState(new Date().getFullYear());
  const [salePrice, setSalePrice] = useState(null);
  const { currentColor } = useStateContext();
  const BASE_URL = window.config.API_URL;
  const [imgUploading, setImgUploading] = useState(false);
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);

  const { user } = AuthContextState;

  const {
    state: ContextState,
    getCategories,
    setPendingFun,
    updateProduct,
  } = useContext(ApisContext);
  const { categories } = ContextState;

  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const [file, setFile] = useState([]);
  const [uploadedfiles, setUploadedfiles] = useState([]);
  const [compressImage, setCompressImage] = useState();

  const fetchProduct = async (productId, user) => {
    setPendingFun(true);
    try {
      const tokenStr = user.token;

      const response = await axios.get(BASE_URL + `products/${productId}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setPendingFun(false);
      if (response.status === 200) {
        if (response.data.Success === true) {
          setProduct(response.data.data);
          // console.log("product successfully getted", response.data.data);

          const productImages = response.data.data?.product_image || [];
          setUploadedfiles((oldArray) => [...oldArray, ...productImages]);
          // console.log(uploadedfiles, "uploadedFiles");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Product not found");
      } else if (response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        toast.dismiss(); //
        toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  

  const uploadImage = async (productImage, user) => {
    setImgUploading(true);

    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + "images", productImage, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setImgUploading(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Image successfully uploaded!");
          console.log(response.data);
          setUploadedfiles((oldArray) => [...oldArray, response?.data?.data]);

          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Banner not added");
      } else if (response.status === 401) {
        // toast.dismiss();
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setImgUploading(false);
      if (error.response.status === 401) {
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
        navigate("/login");
      } else {
        // console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  // console.log(uploadedfiles, "upload");
  const onSave = (file) => {
    let formData = new FormData(); //formdata object
    formData.append("image", file);
    const res = uploadImage(formData, user);
    setValue("images", file);
    // console.log(res, "imageUpload", formData.get("image"), file);
    const newFiles = Array.from([file]);
    const newFileUrls = newFiles?.map((newFile) =>
      URL.createObjectURL(newFile)
    );
    setFile((prevFiles) => [...prevFiles, ...newFileUrls]);
    setCompressImage(null);
  };
  const onCancel = () => { 
    setCompressImage(null);
  }

  function handleChange(e) {
    setCompressImage(e.target.files[0]);
    return null;
  }
  const deleteUploadedImage = async (index) => {
    setPendingFun(true);
    const file = uploadedfiles.find((file) => file);
    const imageId = file.id;
    // console.log(file?.id, "delete uploaded image");
    // deleteImage(imageId, user);
    try {
      const tokenStr = user.token;

      const response = await axios.delete(BASE_URL + `images/${imageId}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setPendingFun(false);
      if (response.status === 200) {
        if (response.data.Success === true) {
          toast.dismiss();
          toast.success("Image successfully deleted!");
          // console.log(response.data);
          setUploadedfiles([
            ...uploadedfiles.slice(0, index),
            ...uploadedfiles.slice(index + 1),
          ]);

          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: image not found");
      } else if (response.status === 401) {
        // toast.dismiss();
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
        navigate("/login");
      } else {
        // console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
    setUploadedfiles([
      ...uploadedfiles.slice(0, index),
      ...uploadedfiles.slice(index + 1),
    ]);
  };


  useEffect(() => {
    getCategories(user);
    fetchProduct(productId, user);
  }, []);

  useEffect(() => {
    // console.log(product, "useEffect");
    setFormvalues(product);
    const cat_ids = product?.categories?.map(category => category?.id);
    setSelectedCategories(cat_ids);
  }, [product]);
  


  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCheckboxChange = (category) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(category)) {
        return prevSelected.filter((selected) => selected !== category);
      } else {
        return [...prevSelected, category];
      }
    });
  };
  useEffect(() => {
    setValue('categories', selectedCategories)
    if (selectedCategories?.length) {
      clearErrors("categories");
    }
  }, [selectedCategories])

  const setFormvalues = (product) => {
    // console.log(product?.published_year !== undefined ? new Date(product?.published_year) : new Date().getFullYear(), "setFormvalues");
    setPublishedYear(
      product?.published_year !== undefined
        ? new Date(product?.published_year)
        : new Date().getFullYear()
    );
    setValue("images", product?.product_image?.[0]);
    setValue("name", product?.title);
    setValue("category", product?.category_id);
    setValue("description", product?.description);
    setValue("price", product?.price);
    setValue("discount", product?.discount_percent);
    setValue("quantity", product?.stock);
    setValue("author", product?.author);
    setValue("pages", product?.page_count);
    setValue("publishedYear", product?.published_year);
    setValue("publisher", product?.publisher);
    setValue("edition", product?.edition);
    setValue("language", product?.language);
    setValue("taxPercent", product?.tax_percent);
    if (product?.delivery_charge) {
      setValue("delivery_charge", product?.delivery_charge );
    }
    if (product?.measurements) {
      setValue("measurements", product?.measurements );
    }
    if (product?.cost_price) {
      setValue("cost_price", product?.cost_price );
    }
    if (product?.weight) {
      setValue("weight", product?.weight );
    }
    setValue("binding", product?.binding);
    setValue("bookType", product?.is_old);
    setSalePrice(
      Math.round(
        product?.price - (product?.price * product?.discount_percent) / 100
      )
    );
    // handleChangeSalePrice(Math.round(product?.price - (product?.price * product?.discount_percent) / 100))
  };

  const handleChangeSalePrice = (salePrice) => {
    const price = watch("price");
    setValue("discount", ((price - salePrice) / price) * 100);
    setSalePrice(salePrice);
  };

  const submit = (productData) => {
    let imageIdArray = uploadedfiles.map((image) => image.id);
    if (selectedCategories.length === 0) {
      setError('categories', {
        type: "manual",
        message: "Select at least 1 category"
      });
      return;
  } 
    // console.log(imageIdArray, "id array");
    let formData = new FormData(); //formdata object
    formData.append("product_image[]", imageIdArray);
    formData.append("title", productData.name);
    if (productData.description) {
      formData.append("description", productData.description);
    }
    formData.append("author", productData.author);
    formData.append("discount_percent", productData.discount);
    formData.append("language", productData.language);
    formData.append("publisher", productData.publisher);
    formData.append("published_year", productData.publishedYear);
    selectedCategories.map((category) => {
      formData.append("category_ids[]", category);
    })
    formData.append("page_count", productData.pages);
    formData.append("price", productData.price);
    formData.append("stock", productData.quantity);
    formData.append("is_old", productData.bookType);
    formData.append("edition", productData.edition);
    formData.append("binding", productData.binding);
    formData.append("tax_percent", productData.taxPercent);
    formData.append("delivery_charge", productData.delivery_charge);
    if (productData?.measurements) {
      formData.append("measurements", productData.measurements);
    }
    if (productData?.cost_price) {
      formData.append("cost_price", productData.cost_price);
    }
    if (productData?.weight) {
      formData.append("weight", productData.weight);
    }
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1] + ":-formatted");
    // }
    updateProduct(formData, productId, user, navigate);
  };

  return (<>
     {compressImage ? <div style={{zIndex:9999999999}} className="fixed inset-0 p-5 flex justify-center items-center bg-black bg-opacity-50 overflow-y-auto">
        <div className="bg-white p-3 rounded w-full">
          <ImageResizer image={compressImage} setImage={onSave} onCancel={onCancel} />
        </div>
      </div> :null }
    <div className="mt-24 sm:mt-8 md:mt-5 px-5 sm:px-8 md:px-14">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-5">
        Update Product
      </div>
      <form onSubmit={handleSubmit((data) => submit(data))} noValidate>
        <div className="grid gap-6 mb-6 md:grid-cols-2">
          <div>
            {!uploadedfiles?.length > 0 ? (
              <div className="items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className={`flex flex-col items-center justify-center w-full h-64 border-2  border-dashed rounded-lg cursor-pointer ${
                    errors.images
                      ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500  dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                      : "border-gray-300 bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  }`}
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    {imgUploading ? (
                      <>
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 text-center dark:text-gray-400">
                          <span className="font-semibold">
                            Click to upload Product Images
                            <span className="text-red-600 text-xs">*</span>
                          </span>{" "}
                          <br />
                          or drag and drop
                        </p>
                      </>
                    )}
                  </div>
                  <input
                    autoComplete="off"
                    id="dropzone-file"
                    type="file"
                    {...register("images", {
                      required: {
                        value: true,
                        message: "Product images required",
                      },
                    })}
                    accept="image/*"
                    onChange={handleChange}
                    className="hidden"
                  />
                </label>

                <small className="text-sm text-red-600 dark:text-red-500">
                  {errors.images?.message}
                </small>
              </div>
            ) : (
              <div>
                {uploadedfiles?.map((file, index) => {
                  return index === 0 ? (
                    <div
                      key={index}
                      className="max-h-60 flex justify-center relative"
                    >
                      <div
                        onClick={() => deleteUploadedImage(index)}
                        className="absolute text-red-600 top-2 right-2 bg-gray-100 cursor-pointer h-7 w-7 rounded-full flex justify-center items-center text-2xl"
                      >
                        <TiDeleteOutline />
                      </div>
                      <img
                        src={file.image}
                        className="object-contain min-h-[175px] rounded-md"
                        alt=""
                      />
                    </div>
                  ) : null;
                })}
                <div className="flex gap-1.5 pb-0.5 pt-2 overflow-x-scroll ">
                  <div className="items-center justify-center w-max">
                    <label
                      htmlFor="dropzone-file"
                      className={`flex flex-col items-center justify-center w-max px-3 h-max border-2  border-dashed rounded-lg cursor-pointer border-gray-300 bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      `}
                    >
                      <div className="flex flex-col items-center justify-center  pb-0">
                        {imgUploading ? (
                          <>
                            <div role="status">
                              <svg
                                aria-hidden="true"
                                className="w-8  h-8 m-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          </>
                        ) : (
                          <>
                            <svg
                              aria-hidden="true"
                              className="w-7 h-7 mb-1 mt-4 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2  leading-none text-sm text-gray-500 text-center dark:text-gray-400">
                              <span className="font-meduim leading-none">
                                Click to upload <br /> More Images
                              </span>{" "}
                            </p>
                          </>
                        )}
                      </div>
                      <input
                        autoComplete="off"
                        id="dropzone-file"
                        type="file"
                        accept="image/*"
                        onChange={handleChange}
                        className="hidden"
                      />
                    </label>
                  </div>
                  {uploadedfiles?.map((file, index) => {
                    return index === 0 ? null : (
                      <div key={index} className="relative max-h-24">
                        <div
                          onClick={() => deleteUploadedImage(index)}
                          className="absolute text-red-600 -top-1 -right-1 bg-gray-100 cursor-pointer h-7 w-7 rounded-full flex justify-center items-center text-2xl"
                        >
                          <TiDeleteOutline />
                        </div>
                        <img
                          width={100}
                          key={index}
                          className="object-cover h-full max-h-24 border rounded-md"
                          src={file.image}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Product Name/Title
                <span className="text-red-600 text-xs">*</span>
              </label>
              <input
                autoComplete="off"
                type="text"
                id="name"
                className={`border text-sm rounded-lg block w-full p-2.5 ${
                  errors.name
                    ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                    : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                } `}
                placeholder="Enter product name"
                {...register("name", {
                  required: {
                    value: true,
                    message: "Product name required",
                  },
                })}
                defaultValue={product?.title}
              />
              <small className="text-sm text-red-600 dark:text-red-500">
                {errors.name?.message}
              </small>
            </div>
            <div className="mb-3">
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Product Description
              </label>
              <textarea
                type="text"
                id="description"
                rows={5}
                className={`border text-sm rounded-lg block w-full p-2.5 ${
                  errors.description
                    ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                    : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                } `}
                placeholder="Enter product Description"
                {...register("description", {
                  required: {
                    value: false,
                    message: "Description required",
                  },
                })}
                defaultValue={product?.description}
              ></textarea>
              <small className="text-sm text-red-600 dark:text-red-500">
                {errors.description?.message}
              </small>
            </div>
          </div>
          <div>
            <div className="mb-3">
              <label
                htmlFor="category"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Select Categories
                <span className="text-red-600 text-xs">*</span>
              </label>
              <div className="flex flex-row items-center gap-3 flex-wrap">
                {categories?.map((category) => (
                  <div key={category?.id} className="flex items-center gap-[1.5px]">
                    <input
                      type="checkbox"
                      id={`category-${category.id}`}
                      value={category.id}
                      // {...register(`categories[${category.id}]`)}
                      checked={selectedCategories?.includes(category.id)}
                      onChange={() => handleCheckboxChange(category.id)}
                    />
                    <label htmlFor={`category-${category.id}`} className="text-sm">
                      {category.category_name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="productPrice"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Product Price
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="number"
              min={0}
              id="productPrice"
              {...register("price", {
                required: { value: true, message: "Price required" },
              })}
              defaultValue={product?.price}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.price
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Product Price"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.price?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="Discount"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Discount % <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="number"
              min={0}
              id="Discount"
              {...register("discount", {
                value: true,
                message: "Discount Required",
              })}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Discount %"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.discount?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="salePrice"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Sale Price
            </label>
            <input
              autoComplete="off"
              type="number"
              id="Discount"
              value={salePrice}
              onChange={(e) => handleChangeSalePrice(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Sale Price"
            />
          </div>
          <div>
            <label
              htmlFor="productQuantity"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Product Quantity
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="number"
              min={0}
              id="productQuantity"
              {...register("quantity", {
                required: { value: true, message: "Enter product quantity" },
              })}
              defaultValue={product?.stock}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.quantity
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="0"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.quantity?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="author"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Book Author
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="text"
              id="author"
              {...register("author", {
                required: { value: true, message: "Enter Book Author" },
              })}
              defaultValue={product?.author}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.author
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Book Author"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.author?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="pages"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Number of Pages
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="number"
              min={0}
              id="pages"
              {...register("pages", {
                required: { value: true, message: "Enter Book pages" },
              })}
              defaultValue={product?.page_count}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.pages
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Number of Pages"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.pages?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="pDate"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Publishing Date
              <span className="text-red-600 text-xs">*</span>
            </label>
            {/*
            <input autoComplete="off"
              type="number"
              id="publishedYear"
              {...register("publishedYear", {
                required: { value: true, message: "Enter Book Publish Date" },
              })}
              defaultValue={product?.published_year}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.publishedYear
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Publish Year"
            />*/}
            <DatePicker
              id="publishedYear"
              {...register("publishedYear", {
                required: { value: true, message: "Enter Book Publish Date" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.publishedYear
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              dateFormat="yyyy"
              placeholderText="Select Publish Year"
              selected={
                publishedYear
                  ? publishedYear
                  : new Date(product?.published_year)
              } // Pass the selected value to the DatePicker component
              onChange={(date) => {
                setValue("publishedYear", date?.getFullYear());
                date && clearErrors("publishedYear");
                setPublishedYear(date);
              }} // Update the form value when the date changes
              showYearPicker
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.publishedYear?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="publisher"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Publisher
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="text"
              id="publisher"
              {...register("publisher", {
                required: { value: true, message: "Enter Book Publisher" },
              })}
              defaultValue={product?.publisher}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.publisher
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Publisher"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.publisher?.message}
            </small>
          </div>

          <div>
            <label
              htmlFor="edition"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Edition
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="text"
              id="edition"
              {...register("edition", {
                required: { value: true, message: "Enter Book Edition" },
              })}
              defaultValue={product?.edition}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.edition
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Book Edition"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.edition?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="language"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Language
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="text"
              id="language"
              {...register("language", {
                required: { value: true, message: "Enter Book Language" },
              })}
              defaultValue={product?.language}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.language
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Book Language"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.language?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="taxPercent"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Tax percent
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="number"
              id="taxPercent"
              {...register("taxPercent", {
                required: { value: true, message: "Enter Book Tax Percent" },
              })}
              defaultValue={product?.tax_percent}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.taxPercent
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Book Tax Percent"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.taxPercent?.message}
            </small>
          </div>
          {/* <div>
            <label
              htmlFor="productTags"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Product Tags
            </label>
            <input autoComplete="off"
              type="text"
              id="productTags"
              {...register("tags")}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Product Tags"
            />
            </div>*/}
          <div>
            <label
              htmlFor="binding"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Binding
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="text"
              id="binding"
              {...register("binding", {
                required: { value: true, message: "Enter Book Binding" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.binding
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Book Binding"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.binding?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="delivery_charge"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Delivery Charge
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="number"
              id="delivery_charge"
              {...register("delivery_charge", {
                required: { value: true, message: "Enter Book Delivery Charge" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors?.delivery_charge
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Book Delivery Charge"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.delivery_charge?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="measurements"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Measurements
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="text"
              id="measurements"
              {...register("measurements", {
                required: { value: false, message: "Enter Book Measurements" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors?.measurements
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Book Measurements"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.measurements?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="cost_price"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Cost Price
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="text"
              id="cost_price"
              {...register("cost_price", {
                required: { value: false, message: "Enter Book cost price" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors?.cost_price
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Book cost price"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.cost_price?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="weight"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Weight
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="text"
              id="weight"
              {...register("weight", {
                required: { value: false, message: "Enter Book Weight" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors?.weight
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Book Weight"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.weight?.message}
            </small>
          </div>
          {/* <div>
            <div className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Select Book Type
              <span className="text-red-600 text-xs">*</span>
            </div>
            <div className="flex gap-4">
              <label
                htmlFor="newBook"
                className="relative flex flex-col bg-white dark:bg-gray-600 p-2 px-3 pe-9 rounded-full shadow-md cursor-pointer"
              >
                <span className="font-semibold text-gray-500 dark:text-gray-50 leading-tight uppercase ">
                  New
                </span>

                <input
                  autoComplete="off"
                  defaultChecked={!product?.is_old}
                  type="radio"
                  {...register("bookType", { required: true })}
                  id="newBook"
                  value="0"
                  className="absolute h-0 w-0 appearance-none"
                />
                <span
                  aria-hidden="true"
                  style={{
                    border: `2px solid ${currentColor}`,
                  }}
                  className="hidden absolute inset-0 rounded-full"
                >
                  <span
                    style={{
                      background: currentColor,
                      backgroundColor: `rgba(${hexToRgb(currentColor)}, 0.3)`,
                    }}
                    className="absolute top-1 right-1 h-6 w-6 inline-flex items-center justify-center rounded-full bg-green-200"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      style={{
                        color: currentColor,
                      }}
                      className="h-5 w-5 "
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
              </label>
              <label
                htmlFor="oldBook"
                className="relative flex flex-col bg-white dark:bg-gray-600 p-2 px-3 pe-9 rounded-full shadow-md cursor-pointer"
              >
                <span className="font-semibold text-gray-500 dark:text-gray-50 leading-tight uppercase">
                  Old
                </span>

                <input
                  autoComplete="off"
                  type="radio"
                  defaultChecked={product?.is_old}
                  {...register("bookType", { required: true })}
                  id="oldBook"
                  value="1"
                  className="absolute h-0 w-0 appearance-none"
                />
                <span
                  aria-hidden="true"
                  style={{
                    border: `2px solid ${currentColor}`,
                  }}
                  className="hidden absolute inset-0 border-2  rounded-full"
                >
                  <span
                    style={{
                      background: currentColor,
                      backgroundColor: `rgba(${hexToRgb(currentColor)}, 0.3)`,
                    }}
                    className="absolute top-1 right-1 h-6 w-6 inline-flex items-center justify-center rounded-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      style={{
                        color: currentColor,
                      }}
                      className="h-5 w-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
              </label>
            </div> 
        </div>
            */}
          </div>
        <div className="text-right">
          <button
            type="submit"
            style={{
              background: currentColor,
            }}
            className=" text-white  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80"
          >
            Update Product
          </button>
        </div>

        {/*<div className="mb-6">
            <label
              htmlFor="success"
              className="block mb-2 text-sm font-medium text-green-700 dark:text-green-500"
            >
              Your name
            </label>
            <input autoComplete="off"
              type="text"
              id="success"
              className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500"
              placeholder="Success input"
              {...register("name", { required: true })}
            />
            <p className="mt-2 text-sm text-green-600 dark:text-green-500">
              <span className="font-medium">Well done!</span> Some success message.
            </p>
  </div> 
  <div>
    <label
      htmlFor="error"
      className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
    >
      Your name
    </label>
    <input autoComplete="off"
      type="text"
      id="error"
      className="bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
      placeholder="Error input"
    />
    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
      <span className="font-medium">Oh, snapp!</span> Some error message.
    </p>
  </div>
*/}
      </form>
    </div>
  </>
  );
};

export default EditProduct;
