import React, { useContext, useEffect, useState } from "react";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useStateContext } from "../contexts/ContextProvider";
import moment from "moment/moment";

import ReactPaginate from "react-paginate";
import { FaRupeeSign } from "react-icons/fa";
import NotFoundImg from "../assets/No data-pana.png";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsFillEyeFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Datepicker from "react-tailwindcss-datepicker";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";

const BASE_URL = window.config.API_URL;

// import Paging from "../components/dashboard/PagingTable";
const Paging = ({ orders, parPageCount }) => {
  const navigate = useNavigate();
  const { setChnageStatus, currentColor } = useStateContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [paymentObjectAcc, setPaymentObjectAcc] = useState(null);
  const { state: ContextState, fetchRevenueReportOrders } =
    useContext(ApisContext);
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;

  const { fetchOrdersPending } = ContextState;

  // console.log(Math.ceil(orders?.length / parPageCount), 'math.ceil(orders?.length / pageCount', orders?.length)
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    // console.log("orders====>", orders);
    const startIndex = parseInt(currentPage) * parseInt(parPageCount);
    const endIndex = parseInt(startIndex) + parseInt(parPageCount);
    setFilterData(orders?.slice(startIndex, endIndex));
  }, [orders]);
  useEffect(() => {
    const startIndex = parseInt(currentPage) * parseInt(parPageCount);
    const endIndex = parseInt(startIndex) + parseInt(parPageCount);
    setFilterData(orders?.slice(startIndex, endIndex));
  }, [currentPage, orders, parPageCount]);

  return (
    <>
      <div className="-my-2 py-2 sm:-mx-6 sm:px-3 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
          <table className="min-w-full px-4">
            <thead>
              <tr className="bg-gray-300 dark:bg-gray-600 dark:text-gray-50 border-b border-gray-200 dark:border-gray-700 text-sm leading-4 uppercase tracking-wider whitespace-nowrap">
                <th className="ps-6 px-3 py-4 text-left font-semibold">
                  ORDER ID
                </th>
                <th className="px-3 py-4 text-left font-semibold">
                  ORDER TIME
                </th>
                <th className="px-3 py-4 text-left font-semibold">
                  CUSTOMER NAME
                </th>
                <th className="px-3 py-4 text-left font-semibold">METHOD</th>
                <th className="px-3 py-4 text-left font-semibold">AMOUNT</th>
                <th className="px-3 py-4 text-center font-semibold">View</th>
                <th className="px-3 py-4 text-left font-semibold">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-500">
              {!fetchOrdersPending &&
                filterData?.map((item, i) => {
                  return (
                    <>
                      <tr
                        key={item?.id}
                        className="border-b border-gray-200 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                      >
                        <td className="ps-6 px-3 py-2 whitespace-nowrap">
                          <div className="text-sm leading-5 ">{item?.id}</div>
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap">
                          <div className="text-sm leading-5">
                            {moment(item?.created_at).format(
                              "MMM D, YYYY h:mm A"
                            )}
                            <br />
                          </div>
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap">
                          <div className="text-sm leading-5 capitalize">
                            {item?.name}
                          </div>
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap uppercase">
                          <div className="text-sm leading-5 flex items-center">
                            {item?.payment_mode}
                            {item?.payment_object ? (
                              <p className="text-xl cursor-pointer">
                                {paymentObjectAcc == item?.payment_object ? (
                                  <MdOutlineExpandLess
                                    onClick={() => setPaymentObjectAcc(null)}
                                  />
                                ) : (
                                  <MdOutlineExpandMore
                                    onClick={() =>
                                      setPaymentObjectAcc(item?.payment_object)
                                    }
                                  />
                                )}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap">
                          <div className="text-sm leading-5 ">
                            &#8377;{item?.total_amount}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-xl text-wk-align">
                          <Link to={`/orders/viewOrder/${item?.id}`}>
                            <BsFillEyeFill />
                          </Link>
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm ">
                          {item?.status === "Pending" && (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                              Pending
                            </span>
                          )}
                          {item?.status === "Processing" && (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                              Processing
                            </span>
                          )}
                          {item?.status === "Cancel" && (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 mr-2 py-0.5 dark:bg-red-900 dark:text-red-300">
                              Canceled
                            </span>
                          )}
                          {item?.status === "Delivered" && (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mr-2 py-0.5 dark:bg-green-900 dark:text-green-300">
                              Delivered
                            </span>
                          )}
                          {item?.status === "Shipped" && (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mr-2 py-0.5 dark:bg-green-900 dark:text-green-300">
                              Shipped
                            </span>
                          )}
                        </td>
                      </tr>
                      {item?.payment_object &&
                      paymentObjectAcc == item?.payment_object ? (
                        <tr
                          key={i}
                          className="border-b border-gray-200 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                        >
                          <td colSpan={7} className="px-3 py-2 ps-6">
                            <div>
                              <h6 className="text-md font-bold">
                                Payment Details:-
                              </h6>
                              <div className="flex gap-2 pt-0.5 ps-3">
                                <div>
                                  <p className="text-sm">Transaction ID:</p>
                                  <p className="text-sm">Payment Mode:</p>
                                  <p className="text-sm">Status:</p>
                                </div>
                                <div>
                                  <p className="text-sm">
                                    {JSON.parse(paymentObjectAcc)?.txnid}
                                  </p>
                                  <p className="text-sm">
                                    {JSON.parse(paymentObjectAcc)?.mode}
                                  </p>
                                  <p className="text-sm uppercase">
                                    {JSON.parse(paymentObjectAcc)?.status}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  );
                })}
              {fetchOrdersPending && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    colSpan={8}
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
              {!fetchOrdersPending && !filterData?.length && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td colSpan={8} className="p-4 py-2 ">
                    <div className="flex justify-center items-left md:items-center flex-col md:flex-row">
                      <img
                        className="max-h-56 w-56 sm:max-w-[450px] sm:max-h-[450px]"
                        src={NotFoundImg}
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              )}
              {!fetchOrdersPending && filterData?.length ? (
                <tr>
                  <td colSpan={8}>
                    <div className="flex justify-between px-4 my-2 items-left md:items-center flex-col md:flex-row">
                      <div className="whitespace-nowrap">
                        <span className="text-sm text-gray-700 dark:text-gray-400">
                          Showing{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {filterData?.length > 0
                              ? currentPage * parseInt(parPageCount) + 1
                              : 0}
                          </span>{" "}
                          to{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {Math.min(
                              (currentPage + 1) * parseInt(parPageCount),
                              orders?.length
                            )}
                          </span>{" "}
                          of{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {orders?.length}
                          </span>{" "}
                          Entries
                        </span>
                      </div>
                      <ReactPaginate
                        previousLabel={<GrFormPrevious />}
                        nextLabel={<GrFormNext />}
                        breakLabel={"..."}
                        initialPage={currentPage}
                        pageCount={Math.ceil(orders?.length / parseInt(parPageCount))}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "items-center inline-flex space-x-2"
                        }
                        pageClassName={
                          "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                        previousLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        disabledLinkClassName={"text-gray-400 opacity-50"}
                        nextLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                        activeLinkClassName={
                          "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                        }
                      />
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const RevenueReport = () => {
  const [parPageCount, setParPageCount] = useState(5);
  const [searchQuerry, setSearchQuerry] = useState("");
  // const [orders, setOrders] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const { state: ContextState, fetchRevenueReportOrders } =
    useContext(ApisContext);
  // const { orders, fetchOrdersPending } = ContextState;
  const { revenueReportOrders } = ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;
  const [value, setValue] = useState({ startDate: null, endDate: null });

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };
  useEffect(() => {
    const start_date = value.startDate;
    const end_date = value.endDate;
    fetchRevenueReportOrders(user, start_date, end_date);
  }, [value]);
  useEffect(() => {
    fetchRevenueReportOrders(user);
  }, []);

  useEffect(() => {
    filterOrders();
  }, [revenueReportOrders]);
  useEffect(() => {
    filterOrders();
  }, [searchQuerry, revenueReportOrders]);

  const filterOrders = () => {
    if (revenueReportOrders && searchQuerry !== "") {
      const filteredOrders = revenueReportOrders?.filter(
        (order) =>
          order.name.toLowerCase().includes(searchQuerry.toLowerCase()) ||
          order.id.toString().toLowerCase().includes(searchQuerry.toLowerCase())
      );
      setFiltered([...filteredOrders]);
    } else {
      setFiltered([...revenueReportOrders]);
    }
  };
  const [processingTotalAmount, setProcessingTotalAmount] = useState(0);
  const [cancelTotalAmount, setCancelTotalAmount] = useState(0);
  const [deliveredTotalAmount, setDeliveredTotalAmount] = useState(0);
  const [pendingTotalAmount, setPendingTotalAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    if (filtered) {
      let newProcessingTotalAmount = filtered.reduce(
        (acc, order) =>
          (order.status === "Processing" &&
            acc + parseInt(order.total_amount)) ||
          acc,
        0
      );
      let newCancelTotalAmount = filtered.reduce(
        (acc, order) =>
          (order.status === "Cancel" && acc + parseInt(order.total_amount)) ||
          acc,
        0
      );
      let newPendingTotalAmount = filtered.reduce(
        (acc, order) =>
          (order.status === "Pending" && acc + parseInt(order.total_amount)) ||
          acc,
        0
      );
      let newDeliveredTotalAmount = filtered.reduce(
        (acc, order) =>
          (order.status === "Delivered" &&
            acc + parseInt(order.total_amount)) ||
          acc,
        0
      );
      const newTotalAmount = filtered.reduce(
        (acc, order) => acc + parseInt(order.total_amount),
        0
      );
      setDeliveredTotalAmount(newDeliveredTotalAmount);
      setPendingTotalAmount(newPendingTotalAmount);
      setCancelTotalAmount(newCancelTotalAmount);
      setProcessingTotalAmount(newProcessingTotalAmount);
      setTotalAmount(newTotalAmount);
    }
  }, [filtered]);
  return (
    <div className="px-5  sm:px-10 sm:mt-8 mt-24">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
        Revenue Report
      </div>

      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-5 border ">
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input autoComplete="off"
              type="text"
              onChange={(e) => setSearchQuerry(e.target.value)}
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
            />
          </div>

          <select
            id="limit"
            onChange={(e) => {
              setParPageCount(e.target.value);
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option defaultValue disabled>
              Order limits
            </option>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-3 px-5 border ">
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
          <div className="w-full">
            <label className="text-md font-medium text-gray-900 dark:text-white mb-3">
              Select Start To End Dates
            </label>
            <Datepicker
              value={value}
              popoverDirection="down" 
              onChange={handleValueChange}
              inputClassName="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            />
          </div>
          <div></div>
        </div>
      </div>
      <Paging orders={filtered} parPageCount={parPageCount} />
      {filtered.length && (
        <>
          <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-5 border ">
            <div className="flex flex-col gap-2">
              <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
                <h1 className="font-semibold flex items-center gap-1 text-gray-900 dark:text-gray-100">
                  Delivered Total:&nbsp;{" "}
                  <span className="flex items-center">
                    <FaRupeeSign />
                    {deliveredTotalAmount}
                  </span>
                </h1>
              </div>
              <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
                <h1 className="font-semibold flex items-center gap-1 text-gray-900 dark:text-gray-100">
                  Processing Total:&nbsp;{" "}
                  <span className="flex items-center">
                    <FaRupeeSign />
                    {processingTotalAmount}
                  </span>
                </h1>
              </div>
              <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
                <h1 className="font-semibold flex items-center gap-1 text-gray-900 dark:text-gray-100">
                  Pending Total:&nbsp;{" "}
                  <span className="flex items-center">
                    <FaRupeeSign />
                    {pendingTotalAmount}
                  </span>
                </h1>
              </div>
              <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
                <h1 className="font-semibold flex items-center gap-1 text-gray-900 dark:text-gray-100">
                  Cancel Total:&nbsp;{" "}
                  <span className="flex items-center">
                    <FaRupeeSign />
                    {cancelTotalAmount}
                  </span>
                </h1>
              </div>
            </div>
            <hr className="my-2" />
            <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
              <h1 className="font-bold flex items-center gap-1 text-gray-900 dark:text-gray-100">
                Grand Total:&nbsp;{" "}
                <span className="flex items-center">
                  <FaRupeeSign />
                  {totalAmount}
                </span>
              </h1>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RevenueReport;
